export const USER_GET_ALL_SET_LOADING = Symbol('USER_SET_LOADING');
export const USER_GET_ALL_SUCCESS = Symbol('USER_GET_ALL_SUCCESS');
export const USER_GET_ALL_FAILED = Symbol('USER_GET_ALL_FAILED');

export const USER_DETAIL_SET_LOADING = Symbol('USER_DETAIL_SET_LOADING');
export const USER_DETAIL_SUCCESS = Symbol('USER_DETAIL_SUCCESS');
export const USER_DETAIL_FAILED = Symbol('USER_DETAIL_FAILED');

export const USER_UPDATE_LOADING = Symbol('USER_UPDATE_LOADING');
export const USER_UPDATE_SUCCESS = Symbol('USER_UPDATE_SUCCESS');
export const USER_UPDATE_FAILED = Symbol('USER_UPDATE_FAILED');

export const USER_ADD_LOADING = Symbol('USER_ADD_LOADING');
export const USER_ADD_SUCCESS = Symbol('USER_ADD_SUCCESS');
export const USER_ADD_FAILED = Symbol('USER_ADD_FAILED');

export const USER_UNLOADED = Symbol('USER_UNLOADED');
