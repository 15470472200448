export const WARD_GET_ALL_SET_LOADING = Symbol('WARD_SET_LOADING');
export const WARD_GET_ALL_SUCCESS = Symbol('WARD_GET_ALL_SUCCESS');
export const WARD_GET_ALL_FAILED = Symbol('WARD_GET_ALL_FAILED');

export const WARD_DETAIL_SET_LOADING = Symbol('WARD_DETAIL_SET_LOADING');
export const WARD_DETAIL_SUCCESS = Symbol('WARD_DETAIL_SUCCESS');
export const WARD_DETAIL_FAILED = Symbol('WARD_DETAIL_FAILED');

export const WARD_UPDATE_LOADING = Symbol('WARD_UPDATE_LOADING');
export const WARD_UPDATE_SUCCESS = Symbol('WARD_UPDATE_SUCCESS');
export const WARD_UPDATE_FAILED = Symbol('WARD_UPDATE_FAILED');

export const WARD_ADD_LOADING = Symbol('WARD_ADD_LOADING');
export const WARD_ADD_SUCCESS = Symbol('WARD_ADD_SUCCESS');
export const WARD_ADD_FAILED = Symbol('WARD_ADD_FAILED');

export const WARD_REMOVE_LOADING = Symbol('WARD_REMOVE_LOADING');
export const WARD_REMOVE_SUCCESS = Symbol('WARD_REMOVE_SUCCESS');
export const WARD_REMOVE_FAILED = Symbol('WARD_REMOVE_FAILED');

export const WARD_ADD_EVENT_SUCCESS = Symbol('WARD_ADD_EVENT_SUCCESS');
export const WARD_DELETE_EVENT_SUCCESS = Symbol('WARD_DELETE_EVENT_SUCCESS');
export const WARD_EDIT_EVENT_SUCCESS = Symbol('WARD_EDIT_EVENT_SUCCESS');

export const WARD_ADD_BULLETIN_SUCCESS = Symbol('WARD_ADD_BULLETIN_SUCCESS');
export const WARD_DELETE_BULLETIN_SUCCESS = Symbol('WARD_DELETE_BULLETIN_SUCCESS');
export const WARD_EDIT_BULLETIN_SUCCESS = Symbol('WARD_EDIT_BULLETIN_SUCCESS');

export const WARD_ADD_SCHEDULE_SUCCESS = Symbol('WARD_ADD_SCHEDULE_SUCCESS');
export const WARD_DELETE_SCHEDULE_SUCCESS = Symbol('WARD_DELETE_SCHEDULE_SUCCESS');
export const WARD_EDIT_SCHEDULE_SUCCESS = Symbol('WARD_EDIT_SCHEDULE_SUCCESS');

export const WARD_UNLOADED = Symbol('WARD_UNLOADED');
