import types from '../types';
import { IWardState } from '../../interfaces/ward.interface';
import { IBulletin } from '../../interfaces/bulletin.interface';
import { IUpcomingEvent } from '../../interfaces/upcomingEvent.interface';
import { ISchedule } from '../../interfaces/schedule.interface';

const defaultState: IWardState = {
  allWards: [],
  loading: false,
  error: null,
  ward: undefined,
  updating: false,
  adding: false,
  removing: {},
  bulletins: [],
  upcomingEvents: [],
  schedule: [],
};

const ward = (state: IWardState = defaultState, action: any) => {
  switch (action.type) {
    // All wards
    case types.WARD_GET_ALL_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.WARD_GET_ALL_SUCCESS:
      return {
        ...state,
        allWards: action.payload,
        error: null,
        loading: false,
      };
    case types.WARD_GET_ALL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    // Ward Information
    case types.WARD_DETAIL_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.WARD_DETAIL_SUCCESS:
      return {
        ...state,
        ward: action.payload.ward,
        upcomingEvents: action.payload.upcomingEvents,
        bulletins: action.payload.bulletins,
        schedule: action.payload.schedule,
        error: null,
        loading: false,
      };
    case types.WARD_DETAIL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    
    // Update
    case types.WARD_UPDATE_LOADING:
      return {
        ...state,
        updating: true,
      };
    case types.WARD_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        updating: false,
      };
    case types.WARD_UPDATE_FAILED:
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    // Add 
    case types.WARD_ADD_LOADING:
      return {
        ...state,
        adding: true,
      };
    case types.WARD_ADD_SUCCESS:
      return {
        ...state,
        error: null,
        addin: false,
      };
    case types.WARD_ADD_FAILED:
      return {
        ...state,
        error: action.error,
        adding: false,
      };

    // Remove 
    case types.WARD_REMOVE_LOADING:
      return {
        ...state,
        removing: {
          [action?.payload]: true,
        },
      };
    case types.WARD_REMOVE_SUCCESS:
      return {
        ...state,
        error: null,
        allWards: state.allWards.filter(
          ward => ward.id !== action?.payload?.id
        ),
        removing: {
          [action?.payload?.id]: false,
        },
      };
    case types.WARD_REMOVE_FAILED:
      return {
        ...state,
        error: action.error,
        removing: {
          [action?.payload]: false,
        },
      };

    // Upcoming events
    case types.WARD_ADD_EVENT_SUCCESS:
      return {
        ...state,
        upcomingEvents: [
          ...state.upcomingEvents,
          action.payload,
        ]
      }

    case types.WARD_DELETE_EVENT_SUCCESS:
      return {
        ...state,
        upcomingEvents: state.upcomingEvents.filter((event: IUpcomingEvent) => event.id !== action.payload)
      }

    case types.WARD_EDIT_EVENT_SUCCESS:
      const editEventIndex = state?.upcomingEvents?.findIndex((b: IUpcomingEvent) => action.payload?.id === b.id)
      if(editEventIndex > -1) {
        const eventCopy: IUpcomingEvent[] = [...state?.upcomingEvents];
        eventCopy[editEventIndex] = action.payload;
        return {
          ...state,
          upcomingEvents: eventCopy,
        }
      }

      return state;

    // Bulletins
    case types.WARD_ADD_BULLETIN_SUCCESS:
      return {
        ...state,
        bulletins: [
          ...state.bulletins,
          action.payload,
        ]
      }

    case types.WARD_DELETE_BULLETIN_SUCCESS:
      return {
        ...state,
        bulletins: state.bulletins.filter((event: IBulletin) => event.id !== action.payload)
      }

    case types.WARD_EDIT_BULLETIN_SUCCESS:
      const editBulletinIndex = state?.bulletins?.findIndex((b: IBulletin) => action.payload?.id === b.id)
      if(editBulletinIndex > -1) {
        const bulletinCopy: IBulletin[] = [...state?.bulletins];
        bulletinCopy[editBulletinIndex] = action.payload;
        return {
          ...state,
          bulletins: bulletinCopy,
        }
      }

      return state;

    // Schedule 
    case types.WARD_ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: [
          ...state.schedule,
          action.payload,
        ]
      }

    case types.WARD_DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: state.schedule.filter((schedule: IBulletin) => schedule.id !== action.payload)
      }

    case types.WARD_EDIT_SCHEDULE_SUCCESS:
      const editScheduleIndex = state?.schedule?.findIndex((b: ISchedule) => action.payload?.id === b.id)
      if(editScheduleIndex > -1) {
        const scheduleCopy: ISchedule[] = [...state?.schedule];
        scheduleCopy[editScheduleIndex] = action.payload;
        return {
          ...state,
          schedule: scheduleCopy,
        }
      }

      return state;

    // Sets the state to initial values
    case types.WARD_UNLOADED:
      return defaultState; 

    default:
      return state;
  }
};

export default ward;

