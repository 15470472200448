import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory, RouteProps } from "react-router-dom";

import routes from '../../utils/routes';
import { isJwtValid, clearToken } from '../../utils/Auth.utils';
import { loggedInUser } from '../../services/auth.service';
import { SET_USER } from '../../redux/types/auth';

import { Header } from '../../components/header';
import Spin from '../../components/Spin';

const Stake = React.lazy(() => import('../../containers/Stake'));
const AddEditStake = React.lazy(() => import('../../containers/Stake/AddEditStake'));
const Ward = React.lazy(() => import('../../containers/Ward'));
const AddEditWard = React.lazy(() => import('../../containers/Ward/AddEditWard'));
const SacramentMeeting = React.lazy(() => import('../../containers/SacramentMeeting'));
const SacramentMeetingForm = React.lazy(() => import('../../containers/SacramentMeeting/SacramentMeetingForm'));
const User = React.lazy(() => import('../../containers/User'));
const UserForm = React.lazy(() => import('../../containers/User/UserForm'));
const Profile = React.lazy(() => import('../../containers/Profile'));
const SuperAdminRoute = React.lazy(() => import('../../components/SuperAdminRoute'));

interface IProps extends RouteProps {}

export const PrivateLayout = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state: any) => state.auth);

  if(!isJwtValid()) {
    history.push(routes.login);
  }

  useEffect(() => {
    loggedInUser()
      .then((response) => {
        dispatch({
          type: SET_USER,
          payload: response.data,
        })
      })
      .catch(() => {
        clearToken();
        history.push(routes.login);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [dispatch, history])

  if(loading || !user) {
    return <Spin />
  }

  return (
    <>
      <Header />
      <React.Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route exact path={routes.stakes} component={Stake} />

          <SuperAdminRoute path={routes.addStake} component={AddEditStake} />

          <Route path={routes.stakeDetail(':id')} component={AddEditStake} />

          <Route exact path={routes.wards} component={Ward} />

          <SuperAdminRoute path={routes.addWard} component={AddEditWard} />

          <Route path={routes.wardDetail(':id')} component={AddEditWard} />

          <Route exact path={routes.sacramentMeetings} component={SacramentMeeting} />

          <SuperAdminRoute path={routes.addSacramentMeeting} component={SacramentMeetingForm} />

          <SuperAdminRoute path={routes.sacramentMeetingDetail(':id')} component={SacramentMeetingForm} />

          <SuperAdminRoute exact path={routes.users} component={User} />

          <SuperAdminRoute path={routes.addUser} component={UserForm} />

          <SuperAdminRoute path={routes.userDetail(':id')} component={UserForm} />

          <Route path={routes.profile} component={Profile} />
        </Switch>
      </React.Suspense>
    </>
  )
}
