import { Http } from '../utils/Http';
import { ILoginInput } from '../interfaces/auth.interface';

export const login = async (data: ILoginInput): Promise<any> => {
  const axios = await Http.axios();

  return await axios.post('/auth/login', data);
}

export const loggedInUser = async (): Promise<any> => {
  const axios = await Http.axios();

  return await axios.get('/auth/me');
}
