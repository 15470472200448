import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'antd/lib/avatar/avatar';
import { Popover } from 'antd';
import { clearProfile, clearToken } from '../../../../utils/Auth.utils';

import styles from './Avatar.module.scss';

export const Content: FC = () => {
  return (
    <div className={styles['Container']}>
      <div className={styles['item']} >
        <span className={styles['profile']}><Link to="/profile">Profile</Link></span>
      </div>

      <div
        className={styles['item']}
        onClick={() => {
          clearToken();
          clearProfile();
          window.location.reload()
        }}
      >
        <span>Logout</span>
      </div>

    </div>
  );
}

export const HeaderAvatar: FC = () => (
  <Popover className={styles['avatar-popover']} placement="bottomRight" content={Content} trigger="click">
    <Avatar />
  </Popover>
);
