export const SACRAMENT_MEETING_SET_LOADING = Symbol('SACRAMENT_MEETING_SET_LOADING');
export const SACRAMENT_MEETING_GET_ALL_SUCCESS = Symbol('SACRAMENT_MEETING_GET_ALL_SUCCESS');
export const SACRAMENT_MEETING_GET_ALL_FAILED = Symbol('SACRAMENT_MEETING_GET_ALL_FAILED');

export const SACRAMENT_MEETING_DETAIL_SET_LOADING = Symbol('SACRAMENT_MEETING_DETAIL_SET_LOADING');
export const SACRAMENT_MEETING_DETAIL_SUCCESS = Symbol('SACRAMENT_MEETING_DETAIL_SUCCESS');
export const SACRAMENT_MEETING_DETAIL_FAILED = Symbol('SACRAMENT_MEETING_DETAIL_FAILED');

export const SACRAMENT_MEETING_UPDATE_LOADING = Symbol('SACRAMENT_MEETING_UPDATE_LOADING');
export const SACRAMENT_MEETING_UPDATE_SUCCESS = Symbol('SACRAMENT_MEETING_UPDATE_SUCCESS');
export const SACRAMENT_MEETING_UPDATE_FAILED = Symbol('SACRAMENT_MEETING_UPDATE_FAILED');

export const SACRAMENT_MEETING_ADD_LOADING = Symbol('SACRAMENT_MEETING_ADD_LOADING');
export const SACRAMENT_MEETING_ADD_SUCCESS = Symbol('SACRAMENT_MEETING_ADD_SUCCESS');
export const SACRAMENT_MEETING_ADD_FAILED = Symbol('SACRAMENT_MEETING_ADD_FAILED');

export const SACRAMENT_MEETING_REMOVE_LOADING = Symbol('SACRAMENT_MEETING_REMOVE_LOADING');
export const SACRAMENT_MEETING_REMOVE_SUCCESS = Symbol('SACRAMENT_MEETING_REMOVE_SUCCESS');
export const SACRAMENT_MEETING_REMOVE_FAILED = Symbol('SACRAMENT_MEETING_REMOVE_FAILED');

export const SACRAMENT_MEETING_UNLOADED = Symbol('SACRAMENT_MEETING_REMOVE_UNLOADED');
