export const STAKE_SET_LOADING = Symbol('STAKE_SET_LOADING');
export const STAKE_GET_ALL_SUCCESS = Symbol('STAKE_GET_ALL_SUCCESS');
export const STAKE_GET_ALL_FAILED = Symbol('STAKE_GET_ALL_FAILED');

export const STAKE_DETAIL_SET_LOADING = Symbol('STAKE_DETAIL_SET_LOADING');
export const STAKE_DETAIL_SUCCESS = Symbol('STAKE_DETAIL_SUCCESS');
export const STAKE_DETAIL_FAILED = Symbol('STAKE_DETAIL_FAILED');

export const STAKE_UPDATE_LOADING = Symbol('STAKE_UPDATE_LOADING');
export const STAKE_UPDATE_SUCCESS = Symbol('STAKE_UPDATE_SUCCESS');
export const STAKE_UPDATE_FAILED = Symbol('STAKE_UPDATE_FAILED');

export const STAKE_ADD_LOADING = Symbol('STAKE_ADD_LOADING');
export const STAKE_ADD_SUCCESS = Symbol('STAKE_ADD_SUCCESS');
export const STAKE_ADD_FAILED = Symbol('STAKE_ADD_FAILED');

export const STAKE_REMOVE_LOADING = Symbol('STAKE_REMOVE_LOADING');
export const STAKE_REMOVE_SUCCESS = Symbol('STAKE_REMOVE_SUCCESS');
export const STAKE_REMOVE_FAILED = Symbol('STAKE_REMOVE_FAILED');

export const STAKE_ADD_EVENT_SUCCESS = Symbol('STAKE_ADD_EVENT_SUCCESS');
export const STAKE_EDIT_EVENT_SUCCESS = Symbol('STAKE_EDIT_EVENT_SUCCESS');
export const STAKE_DELETE_EVENT_SUCCESS = Symbol('STAKE_DELETE_EVENT_SUCCESS');

export const STAKE_ADD_BULLETIN_SUCCESS = Symbol('STAKE_ADD_BULLETIN_SUCCESS');
export const STAKE_EDIT_BULLETIN_SUCCESS = Symbol('STAKE_EDIT_BULLETIN_SUCCESS');
export const STAKE_DELETE_BULLETIN_SUCCESS = Symbol('STAKE_DELETE_BULLETIN_SUCCESS');

export const STAKE_UNLOADED = Symbol('STAKE_UNLOADED');
