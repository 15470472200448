import types from '../types';
import { IUserState, IUser } from '../../interfaces/user.interface';

const defaultState: IUserState = {
  allUsers: [],
  loading: false,
  error: null,
  user: {} as IUser,
  updating: false,
  adding: false,
};

const user = (state: IUserState = defaultState, action: any) => {
  switch (action.type) {
    // All users
    case types.USER_GET_ALL_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.USER_GET_ALL_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
        error: null,
        loading: false,
      };
    case types.USER_GET_ALL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    // User Information
    case types.USER_DETAIL_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.USER_DETAIL_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
        loading: false,
      };
    case types.USER_DETAIL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    
    // Update
    case types.USER_UPDATE_LOADING:
      return {
        ...state,
        updating: true,
      };
    case types.USER_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        updating: false,
      };
    case types.USER_UPDATE_FAILED:
      return {
        ...state,
        error: action.error,
        updating: false,
      };

    // Add 
    case types.USER_ADD_LOADING:
      return {
        ...state,
        adding: true,
      };
    case types.USER_ADD_SUCCESS:
      return {
        ...state,
        error: null,
        addin: false,
      };
    case types.USER_ADD_FAILED:
      return {
        ...state,
        error: action.error,
        adding: false,
      };

    // Sets the state to initial values
    case types.USER_UNLOADED:
      return defaultState; 

    default:
      return state;
  }
};

export default user;

