const routes = {
  dashboard: '/',
  login: '/auth/login',
  stakes: '/stakes',
  addStake: '/stakes/add',
  stakeDetail: (id: string) =>  `/stakes/${id}`,
  wards: '/wards',
  addWard: '/wards/add',
  wardDetail: (id: string) => `/wards/${id}`,
  sacramentMeetings: '/sacrament-meetings',
  addSacramentMeeting: '/sacrament-meetings/add',
  sacramentMeetingDetail: (id: string) => `/sacrament-meetings/${id}`,
  users: '/users',
  addUser: '/users/add',
  userDetail: (id: string) => `/users/${id}`,
  profile: `/profile`,
}

export default routes;
