import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from "react-router-dom";
import {Provider} from 'react-redux';

import { PublicLayout } from './layouts/public';
import { PrivateLayout } from './layouts/private';
import store from './redux/stores';

export const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path="/auth/*" component={PublicLayout} />
          <Route path="/*" component={PrivateLayout} />
        </Switch>
      </Router>
    </Provider>
  );
}

//export default withAuthContext(App);
export default App;
