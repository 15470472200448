import * as stake from './stake';
import * as ward from './ward';
import * as sacramentMeeting from './sacramentMeeting';
import * as user from './user';
import * as auth from './auth';

const combined = {
  ...stake,
  ...ward,
  ...sacramentMeeting,
  ...user,
  ...auth,
};

export default combined;

