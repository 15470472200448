export const MenuLinks = [
  {
    name: 'Stakes',
    link: '/stakes',
    needAdmin: false,
  },
  {
    name: 'Wards',
    link: '/wards',
    needAdmin: false,
  },
  {
    name: 'Sacrament Meeting',
    link: '/sacrament-meetings',
    needAdmin: true,
  },
  {
    name: 'Users',
    link: '/users',
    needAdmin: true,
  },
]
