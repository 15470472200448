import axios, { AxiosInstance } from 'axios';
import { getToken } from './Auth.utils';

export class Http {
  static async axios(contentType = 'application/json'): Promise<AxiosInstance> {
    return new Promise(resolve => {
      const token = getToken();
      
      const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          contentType: contentType,
          Authorization: `Bearer ${token}`
        },
      });
      return resolve(instance);
    });
  }

  static AddQueryParams(url: string, obj: any) {
    if (!obj) { return url; }
    const keys = Object.keys(obj);

    if (keys.length === 0) return url;

    if (keys.length === 1) return `${url}?${keys[0]}=${obj[keys[0]]}`;

    const params = keys.reduce((accumulator: string, currentValue: string, index: number) => {
      if (index === 1) return `?${accumulator}=${obj[accumulator]}&${currentValue}=${obj[currentValue]}`;
      accumulator += `&${currentValue}=${obj[currentValue]}`;
      return accumulator;
    });

    return url + params;
  }
}
