import { Spin as AntdSpin } from 'antd';

import styles from './Spin.module.scss';

const Spin = () => {
  return (
    <div className={styles['spin-container']}>
      <AntdSpin />
    </div>
  )
}

export default Spin;

