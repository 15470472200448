import React from 'react';
import { Switch, Route, useHistory } from "react-router-dom";

import routes from '../../utils/routes';
import { isJwtValid } from '../../utils/Auth.utils';

const Login = React.lazy(() => import('../../containers/Login'));

export const PublicLayout = () => {
  const history = useHistory();

  if(isJwtValid()) {
    history.push(routes.dashboard);
  }

  return (
    <>
      <React.Suspense fallback={<p>Loading...</p>}>
        <Switch>
          <Route path={routes.login} component={Login} />
        </Switch>
      </React.Suspense>
    </>
  )
}

export default PublicLayout;
