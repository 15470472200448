import types from '../types';
import { ISacramentMeetingState } from '../../interfaces/sacramentMeeting.interface';


const defaultState: ISacramentMeetingState = {
  allSacramentMeetings: [],
  loading: false,
  error: null,
  removing: {},
};

const sacramentMeeting = (state: ISacramentMeetingState = defaultState, action: any) => {
  switch (action.type) {
    case types.SACRAMENT_MEETING_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SACRAMENT_MEETING_GET_ALL_SUCCESS:
      return {
        ...state,
        allSacramentMeetings: action.payload,
        error: null,
        loading: false,
      };
    case types.SACRAMENT_MEETING_GET_ALL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    // Remove 
    case types.SACRAMENT_MEETING_REMOVE_LOADING:
      return {
        ...state,
        removing: {
          [action?.payload]: true,
        },
      };
    case types.SACRAMENT_MEETING_REMOVE_SUCCESS:
      return {
        ...state,
        error: null,
        allSacramentMeetings: state.allSacramentMeetings.filter(
          sacramentMeeting => sacramentMeeting.id !== action?.payload?.id
        ),
        removing: {
          [action?.payload?.id]: false,
        },
      };
    case types.SACRAMENT_MEETING_REMOVE_FAILED:
      return {
        ...state,
        error: action.error,
        removing: {
          [action?.payload]: false,
        },
      };

    // Sets the state to initial values
    case types.SACRAMENT_MEETING_UNLOADED:
      return defaultState; 

    default:
      return state;
  }
};

export default sacramentMeeting;
