import React from 'react';

import { HeaderAvatar } from './components/Avatar';
import { Menu } from './components/Menu';

import styles from './Header.module.scss';

export const Header = () => {
  return (
    <div className={styles['Header']}>
      <Menu />
      <HeaderAvatar />
    </div>
  )
};
