import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { MenuLinks } from './Menu.Links';
import { history } from '../../../../App';

import styles from './Menu.module.scss';

export const Menu: FC = () => {
  const { user = {} } = useSelector((state: any) => state.auth);

  return (
    <div className={styles['Menu']}>
      {
      MenuLinks
        .filter((menu: any) => {
          if(user?.super_admin) {
            return true;
          } else {
            return user?.super_admin === menu.needAdmin
          }
        })
        .map(x => (
          <div key={`menu_${x.name}`} className={styles['item']} onClick={() => history.push(x.link)}>
            {x.name}
          </div>
        ))
      }
    </div>
  )
};
