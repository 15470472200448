import types from '../types';
import { IAuthState } from '../../interfaces/auth.interface';

const defaultState: IAuthState = {
  loading: false,
  error: null,
  user: null,
};

const user = (state: IAuthState = defaultState, action: any) => {
  switch (action.type) {
    // All users
    case types.AUTH_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload, 
      };
    case types.LOGIN_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case types.SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default user;

