import {combineReducers} from 'redux';
import stakes from './stake';
import wards from './ward';
import sacramentMeetings from './sacramentMeeting';
import users from './user';
import auth from './auth';

export default combineReducers({
  stakes,
  wards,
  sacramentMeetings,
  users,
  auth,
});

