import types from '../types';
import { IStakeState } from '../../interfaces/stake.interface';
import { IBulletin } from '../../interfaces/bulletin.interface';
import { IUpcomingEvent } from '../../interfaces/upcomingEvent.interface';

const defaultState: IStakeState = {
  allStakes: [],
  loading: false,
  error: null,
  stake: undefined,
  removing: {},
  bulletins: [],
  upcomingEvents: [],
};

const stake = (state: IStakeState = defaultState, action: any) => {
  switch (action.type) {
    case types.STAKE_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STAKE_GET_ALL_SUCCESS:
      return {
        ...state,
        allStakes: action.payload,
        error: null,
        loading: false,
      };
    case types.STAKE_GET_ALL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    // Stake Information
    case types.STAKE_DETAIL_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.STAKE_DETAIL_SUCCESS:
      return {
        ...state,
        stake: action.payload.stake,
        bulletins: action.payload.bulletins,
        upcomingEvents: action.payload.upcomingEvents,
        error: null,
        loading: false,
      };
    case types.STAKE_DETAIL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    // Remove 
    case types.STAKE_REMOVE_LOADING:
      return {
        ...state,
        removing: {
          [action?.payload]: true,
        },
      };
    case types.STAKE_REMOVE_SUCCESS:
      return {
        ...state,
        error: null,
        allStakes: state.allStakes.filter(
          stake => stake.id !== action?.payload?.id
        ),
        removing: {
          [action?.payload?.id]: false,
        },
      };
    case types.STAKE_REMOVE_FAILED:
      return {
        ...state,
        error: action.error,
        removing: {
          [action?.payload]: false,
        },
      };

    // Upcoming event
    case types.STAKE_ADD_EVENT_SUCCESS:
      return {
        ...state,
        upcomingEvents: [
          ...state.upcomingEvents,
          action.payload,
        ]
      }

    case types.STAKE_DELETE_EVENT_SUCCESS:
      return {
        ...state,
        upcomingEvents: state.upcomingEvents.filter((event: IUpcomingEvent) => event.id !== action.payload)
      }

    case types.STAKE_EDIT_EVENT_SUCCESS:
      const editEventIndex = state?.upcomingEvents?.findIndex(b => action.payload?.id === b.id)
      if(editEventIndex > -1) {
        const eventCopy = [...state?.upcomingEvents];
        eventCopy[editEventIndex] = action.payload;
        return {
          ...state,
          upcomingEvents: eventCopy,
        }
      }

      return state;

    // Bulletin
    case types.STAKE_ADD_BULLETIN_SUCCESS:
      return {
        ...state,
        bulletins: [
          ...state.bulletins,
          action.payload,
        ]
      }

    case types.STAKE_DELETE_BULLETIN_SUCCESS:
      return {
        ...state,
        bulletins: state.bulletins.filter((event: IBulletin) => event.id !== action.payload)
      }

    case types.STAKE_EDIT_BULLETIN_SUCCESS:
      const editBulletinIndex = state?.bulletins?.findIndex(b => action.payload?.id === b.id)
      if(editBulletinIndex > -1) {
        const bulletinCopy = [...state?.bulletins];
        bulletinCopy[editBulletinIndex] = action.payload;
        return {
          ...state,
          bulletins: bulletinCopy,
        }
      }

      return state;

    // Sets the state to initial values
    case types.STAKE_UNLOADED:
      return defaultState; 

    default:
      return state;
  }
};

export default stake;

